/**
 * Type: ページ
 * What: 夏・１周年記念 昆虫展
 */
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import BreadcrumbComponent from '../../components/Breadcrumb';
import { Button } from '../../components/Btn';

const pageTitle = 'お問い合わせ';
const pageSlug = 'chat';

export const query = graphql`
  {
    site {
      siteMetadata {
        uuidEvent
        ticket
        ticketReady
      }
    }
  }
`;

// Data Props Template
const Template = ({ data }) => {
  const url = data.site.siteMetadata;

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="container u-pY-40 u-sp:pX-15">
        <h3 className="title is-3">{pageTitle}</h3>
        <div className="u-mt-20 u-pc:ta-c">
          <p className="u-pc:fz-16 u-sp:fz-14">
            お問合せはチャットボットをご利用ください。
          </p>
          <p className="u-mt-10 u-fz-12 ">
            入力例）
            <br className="u-sp" />
            チケット　購入<span className="u-pc">　／　</span>
            <br className="u-sp" />
            駐車場　割引　など単語で検索
          </p>
        </div>

        <div className="u-mX-auto u-mt-20" style={{ maxWidth: '640px' }}>
          <iframe
            width="640"
            height="460"
            src="https://app.chatplus.jp/chat/visitor/435b286a_3?t=btn"
            title="chatbot"
            frameBorder="0"
            style={{ width: '100%' }}
          />
        </div>

        <div className="btn-wrap u-mt-30">
          <Button
            className="btn-regular"
            href="https://faq.softbankhawks.co.jp/category/show/128?site_domain=open"
            target="_blank"
            rel="noopener noreferrer"
          >
            よくある問合わせ(FAQ)は
            <br />
            こちらから
          </Button>
        </div>
      </section>
    </Layout>
  );
};

export default Template;
